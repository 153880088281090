<template>
    <div class="page page-privacy">
        <div class="privacy-content">
            <div class="item">
                <div class="title">PTE Game Privacy Policy</div>
                <div class="info">
                    <p>- What information we collect</p>
                    <p>- What it used for</p>
                    <p>You can read the PTE GAME Privacy Policy (this "Privacy Policy") to learn more about how we collect and use personal information, to better understand our services, and to make appropriate choices.</p>
                    <p><b>Information collection:</b></p>
                    <p>We collect only the personal information necessary to perform the functions of our products in accordance with legal and regulatory requirements, and the principles of legality, legitimacy, necessity, and good faith. Types of information collected:</p>
                    <p>- Information that you voluntarily provide when using our services<br />
                    email addresses, nicknames, etc. that you voluntarily fill in or upload when you register or use our products or services
                        Information you submit through our customer service or when you attend one of our events</p>
                    <p>- Information we obtain when you use our services<br />
                        When you use our services, we may collect your IP information in order to maintain the safe and stable operation of our products and services.</p>
                    <p>- Remarketing cookies</p>
                    <p>Information is collected through google’s search engine to best serve you relatable and desired content. If you wish to opt out of these services, please visit <a href="https://support.google.com/ads/answer/2662922?hl=en">https://support.google.com/ads/answer/2662922?hl=en</a></p>
                    <p><b>Use of data:</b></p>
                    <p>We use the information we collect for the following purposes in strict compliance with laws and regulations and our agreements with users. If we use your information for purposes other than those listed below, we will explain this to you and obtain your consent.</p>
                    <p>- To provide you with services</p>
                    <p>- To meet your individual needs</p>
                    <p>- Security and safety</p>
                    <p>- Managing the software</p>
                    <p><b>Changes to this privacy policy</b></p>
                    <p>We may update our Privacy Policy from time to time.<br />
                    e will notify you of any changes by posting the new Privacy Policy on this page before these changes.<br />
                        In the privacy protection guidelines for specific products and services, if the purpose of processing personal information, the method of processing, and the type of personal information processed are changed, your consent will be obtained again.</p>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'About'
}
</script>

<style lang="less">
@import "../../less/main.less";
.page-privacy {
    .privacy-content {
        width: 800px;
        margin: 0 auto;
        @media @mobile {
            width: 100%;
        }
        .item {
            padding: 36px;
            p {
                padding-bottom: 16px;
                color: #ccc;
                b {
                    font-size: 18px;
                    font-weight: bold;
                    color: #fff;
                }
            }
            .title {
                padding-bottom: 36px;
                font-size: 24px;
                text-align: center;
                font-family: GothamBold;
            }
            .info {
                text-align: center;
                font-size: 18px;
                line-height: 28px;
                h2, p {
                    text-align: left;
                }
            }
        }
    }
}
</style>
